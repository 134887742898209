import React, { useState } from "react";
import { UserContext } from "../context/User";
import StatBox from "./StatBox";
import { calculateRewardsExp } from "../utils";
import { crewBadge, rewards } from "../data/rewards";

const Rewards = () => {
  const { user } = React.useContext(UserContext);
  const [percentage, setPercentage] = useState(0);

  React.useEffect(() => {
    const max = 4500;
    const current = user.customerOrderTotal;
    const percentage = (current / max) * 100;

    const delay = setTimeout(() => {
      setPercentage(percentage);
    }, 200);

    return () => clearTimeout(delay);
  }, []);

  const renderRewards = () => {
    const userRewards = [...rewards];

    userRewards.sort((a, b) => {
      // Check if either tier array includes 0
      var includesZeroA = a.tiers.includes(0);
      var includesZeroB = b.tiers.includes(0);

      // Compare the values and place the one with 0 tiers first
      if (includesZeroA && !includesZeroB) {
        return -1; // a should come before b
      } else if (!includesZeroA && includesZeroB) {
        return 1; // b should come before a
      } else {
        return 0; // maintain the original order
      }
    });

    return userRewards.map((reward, i) => {
      const tierDisabled = "opacity-50 cursor-not-allowed";
      return (
        <div
          key={reward.id}
          className={`${
            !reward.tiers.includes(user.tier) && tierDisabled
          } cursor-pointer box-shadow flex justify-center items-center py-12 hover:scale-105 transition-all duration-200 ease-in-out`}
        >
          <div className="reward-box block text-center">
            <p className="m-0 text-xl font-bold text-slate-800 pb-2">
              {reward.name}
            </p>
            <p className="m-0 text-slate-600">{reward.description}</p>
          </div>
        </div>
      );
    });
  };

  const renderRewardBar = () => {
    return (
      <>
        <div className="reward-bar bg-slate-100 w-full rounded-full flex relative overflow-hidden">
          <div className="border-r-2 border-black mix-blend-multiply opacity-30 w-1/3 z-20 relative"></div>
          <div className="border-r-2 border-black mix-blend-multiply opacity-30 w-1/3 z-20 relative"></div>
          <div
            className="reward-amount absolute bg-sky-300 "
            style={{ width: percentage + "%", height: "100%" }}
          ></div>
        </div>
        <div className="flex justify-between">
          <p className="text-sm text-slate-600 w-1/3">$0</p>
          <p className="text-sm text-slate-600 w-1/3 text-left"> $1,500</p>
          <p className="text-sm text-slate-600 w-1/3">$3,000+</p>
        </div>
      </>
    );
  };
  return (
    <div className="">
      <p className="font-bold text-2xl m-0 pb-8">Blizzard Rewards</p>

      <div className="flex items-center" style={{ width: "95%" }}>
        <StatBox
          classes="bg-emerald-500 mr-4 "
          metric={
            user.blizzard_rewards_balance ? user.blizzard_rewards_balance : 0
          }
          label="Balance"
          altLabel={"Credit"}
          type="money"
        />
        <StatBox
          classes="bg-teal-500 mr-4 "
          metric={calculateRewardsExp()}
          label="Expires in"
          type="time"
          altLabel="Days"
        />

        <StatBox classes="bg-sky-500" icon={crewBadge} label="Reward Tier" />
      </div>
      <div className="flex">
        <div className="w-1/3 pb-2 text-slate-800 font-bold text-left">
          Crew
        </div>
        <div className="w-1/3 pb-2 text-slate-800 font-bold text-left">
          Headliner
        </div>
        <div className="w-1/3 pb-2 text-slate-800 font-bold text-left">
          Legend
        </div>
      </div>
      {renderRewardBar()}

      <p className="font-bold text-xl m-0 py-8">Rewards</p>
      <div className="grid grid-cols-3 gap-6" style={{ maxWidth: 960 }}>
        {renderRewards()}
      </div>
    </div>
  );
};

export default Rewards;
