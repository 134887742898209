import React, { useState } from "react";

const Input = ({ maxLength, label, value, onChange, classes, id, type }) => {
  return (
    <div className={classes}>
      {label && (
        <label>
          <p className="pb-1 text-slate-600">{label}</p>
        </label>
      )}
      <input
        onChange={(value) => onChange(value, id)}
        type={type ? type : "text"}
        placeholder={label}
        value={value}
        className="w-full border-2 border-slate-200 rounded-lg p-4 mb-2"
      />
    </div>
  );
};

export default Input;
