import React, { useState } from "react";
import Orders from "./Orders";
import Addresses from "./Addresses";
import StatBox from "./StatBox";
import { UserContext } from "../context/User";
import { calculateTotal, getTierBadge } from "../utils";
const Overview = ({ setPage }) => {
  const { user } = React.useContext(UserContext);

  return (
    <div className="">
      <div className="flex" style={{ width: "95%", maxWidth: 700 }}>
        <StatBox
          classes="bg-teal-500 mr-1 lg:mr-4"
          metric={user.orders.length}
          label="Orders"
        />
        <StatBox
          classes="bg-cyan-400 mr-1 lg:mr-4"
          metric={calculateTotal(user.orders)}
          type="money"
          label="Spent"
        />
        <StatBox
          classes="bg-sky-500"
          icon={getTierBadge(user.tier)}
          label="Reward Tier"
        />
      </div>
      <Orders limit={4} />
      <div className="flex mt-4 flex-wrap">
        <div className="w-full lg:w-2/3 lg:mr-4">
          <Addresses limit={1} setPage={setPage} />
        </div>
        <div className="p-8 flex-grow box-shadow rounded-lg border-t-8 border-teal-500">
          <p className="font-bold text-xl m-0">Quick Actions</p>
          <div className="block pt-3">
            {/* <a className="text-slate-800 block pb-2" href="#">
              Reset Password
            </a> */}
            <a
              href="https://www.blizzardpro.com/support"
              target="_blank"
              className="text-slate-800 block pb-2"
            >
              Get Support
            </a>
            <a
              className="text-slate-800 block pb-2"
              href="https://www.portal.blizzardpro.com/?type=signup&newReseller=true"
              target="_blank"
            >
              Become a Reseller
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
