import React, { useState } from "react";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border rounded cursor-pointer">
      <div
        className="grid grid-cols-5 px-3 bg-white mb-1 rounded border-b border-slate-500 border-dotted last:border-none"
        onClick={toggleAccordion}
      >
        {title}
      </div>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};

export default Accordion;
