import React, { useState } from "react";
import Sidebar from "./Sidebar";
import View from "./View";
import { UserContext } from "../context/User";
import Loading from "./Loading";

const Dashboard = () => {
  const { user, loading } = React.useContext(UserContext);
  const [page, setPage] = useState("overview");

  const [navVisible, setNavVisible] = useState(false);

  const handleRedirect = () => {
    //get redirect from query string
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirect = urlParams.get("redirect");
    if (redirect) {
      window.location.href = `https://www.blizzardpro.com/account?type=signin&redirect=${redirect}`;
    } else {
      return;
    }
  };

  return (
    <div className="">
      <div className="p-4 bg-slate-100 font-bold">
      <a href="https://www.blizzardpro.com" target="_blank" style={{textDecoration: "none"}} className="text-blue-700"><i class="fa-regular fa-chevron-left text-xs pr-2"></i> Go to blizzardpro.com</a>
      </div>

      {user ? (
        <div className="flex flex-end">
          <div
            onClick={() => setNavVisible(!navVisible)}
            className="text-3xl text-slate-700 fixed lg:hidden right-5 top-5 mobile-menu hover:text-primary z-10 cursor-pointer"
          >
            {navVisible ? (
              <i className="fa-solid fa-xmark "></i>
            ) : (
              <i className="fa-regular fa-bars-staggered"></i>
            )}
          </div>
          <div
            className={`sidebar border-b-2 lg:border-r-2 border-slate-200 w-full h-screen lg:p-10 ${
              navVisible && "sidebar-visible"
            }`}
          >
            <Sidebar page={page} setPage={setPage} />
          </div>
          <div className="w-full lg:fixed right-0 blizz-customer-dashboard view flex-grow mt-16 p-2 lg:mt-0 lg:p-20">
            <View page={page} setPage={setPage} />
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap w-full justify-center h-screen items-center">
          {handleRedirect()}

          <div className="text-center">
            {loading ? (
              <Loading />
            ) : (
              <>
                <p className="text-2xl font-bold w-full">
                  You are not logged in.
                </p>
                <a
                  href={`https://www.blizzardpro.com/account?type=signin&redirect=${window.location.href}`}
                  className="bg-black text-white py-3 px-8 rounded-full"
                >
                  Log in
                </a>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
