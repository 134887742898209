import React, { useState } from "react";
import { calculateTotal, makeAxiosCall, tierCalc } from "../utils.js";
import axios from "axios";

export const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [tokenExpired, setTokenExpired] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState();
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);

  const fetchUser = () => {
    setLoading(true);
    makeAxiosCall("get", "/bear")
    .then(()=> {
      makeAxiosCall("get", `/customer-info`)
      .then((res) => {
        setLoading(false);
        const customerOrderTotal = calculateTotal(res.data.orders);
        const tier = tierCalc(res.data.orders);
        setUser({ ...res.data, customerOrderTotal, tier });
        setIsLoggedIn(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setIsLoggedIn(false);
      });
    })
  };

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const t = urlParams.get("token");

    if (t) {
      setLoading(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${t}`;
      makeAxiosCall("get", `/bear`)
        .then((res) => {
          fetchUser();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      fetchUser();
    }
  }, []);

  const updateAddress = (address) => {
    makeAxiosCall("put", `/address`, address).then((res) => {
      console.log(res);
      setUser({ ...user, addresses: res.data });
    });
  };

  const signOut = () => {
    makeAxiosCall("get", `/logout`).then((res) => {
      console.log(res);
      setUser();
      setIsLoggedIn(false);
      window.location.href = "https://www.blizzardpro.com";
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        signOut,
        setUser,
        tokenExpired,
        loading,
        updateAddress,
        setTokenExpired,
        isLoggedIn,
        setIsLoggedIn,
        token,
        setToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
