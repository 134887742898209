import React, { useState } from "react";
import { UserContext } from "../context/User";
import Payment from "./Payment";
import Modal from "./Modal";
import CCForm from "./CCForm";

const PaymentBook = () => {
  const { user } = React.useContext(UserContext);

  console.log(user);
  const renderPayments = () => {
    if (user.payment_profiles && user.payment_profiles.length > 0) {
      return user.payment_profiles.map((payment, index) => {
        return <Payment payment={payment} />;
      });
    } else {
      return (
        <div>
          <p>You have no payment methods</p>
          <Modal title="Add Payment Method">
            <CCForm />
          </Modal>
        </div>
      );
    }
  };
  return (
    <div className="p-6 box-shadow rounded-lg border-t-8 border-sky-500">
      <p className="font-bold text-2xl m-0">Payment Methods</p>
      {renderPayments()}
    </div>
  );
};

export default PaymentBook;
