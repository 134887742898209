import React from "react";

import { formatMoney } from "../utils";

const StatBox = ({ classes, metric, label, type, icon, altLabel }) => {
  return (
    <div
      style={{ width: 220, height: 220 }}
      className={`${classes} text-white stat-box py-12 mb-10 rounded-2xl text-center`}
    >
      <div className="pb-3 text-lg font-bold">{label}</div>
      <div
        className={`stat-metric font-bold flex items-center ${
          type === "money" && "add-money"
        }`}
      >
        {metric >= 0 && (
          <p className="m-auto" style={{ fontSize: 30 }}>
            {type === "money" ? formatMoney(metric) : metric}
          </p>
          // <AnimatedNumbers
          //   fontStyle={{ fontSize: 30 }}
          //   includeComma
          //   animateToNumber={metric}
          //   locale="en-US"
          // />
        )}
        {icon && <div style={{ width: "70%", margin: "0 auto" }}>{icon}</div>}
      </div>
      {altLabel && <p className="pt-3  m-0 text-lg font-bold">{altLabel}</p>}
    </div>
  );
};

export default StatBox;
