import React, { useState } from "react";
import Button from "./Button";

const Modal = ({
  title,
  children,
  defaultState,
  disableButton,
  disableClose,
  className,
  showCloseButton,
  disableOverlay,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    setIsOpen(defaultState);
  }, [defaultState]);

  return (
    <>
      {disableButton ? null : (
        <Button onClick={() => setIsOpen(!isOpen)} title={title} />
      )}
      {isOpen && (
        <div className={`${className} fixed z-50 inset-0 overflow-y-auto`}>
          <div className="flex items-center justify-center min-h-screen">
            {disableOverlay ? null : (
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  zIndex: 50,
                  opacity: 0.8,
                }}
                className="modal-bg fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-black opacity-75"></div>
              </div>
            )}

            <div
              className="bg-gray-300 rounded-lg overflow-hidden shadow-xl transform transition-all w-full"
              style={{ maxWidth: 920, zIndex: 51 }}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {!disableClose && (
                <div className="bg-gray-300 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                    onClick={handleClose}
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}

              <div className="bg-gray-300 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                {children}
                {showCloseButton && (
                  <div className="m-auto justify-center flex pb-6">
                    <Button onClick={() => handleClose()} title="Close" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
