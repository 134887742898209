import Dashboard from "./components/Dashboard";
import UserProvider from "./context/User";

function App() {
  return (
    <div className="m-auto " style={{ width: "100%" }}>
      <UserProvider>
        <Dashboard />
      </UserProvider>
    </div>
  );
}

export default App;
