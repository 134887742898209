import React, { useState } from "react";
import Button from "./Button";
import Input from "./Input";
import { UserContext } from "../context/User";

const CCForm = ({ card }) => {
  const initCC = {
    cardNumber: "",
    cardName: "",
    expDate: "",
    cvv: "",
  };

  //   const { updateCard } = React.useContext(UserContext);
  const [newCard, setNewCard] = React.useState();

  React.useEffect(() => {
    if (setNewCard(card)) {
      setNewCard(card);
    } else {
      setNewCard(initCC);
    }
  }, []);

  const updateCard = (value, label) => {
    setNewCard({ ...newCard, [label]: value });
  };

  const formatCreditCardNumber = (number) => {
    let value = number.replace(/\s+/g, "").trim();
    value = value.replace(/(\d{4})(?=\d)/g, "$1 ");

    if (value.length > 19) {
      value = value.substr(0, 19);
    }

    setNewCard({ ...newCard, cardNumber: value });
  };

  const handleExpChange = (e) => {
    let value = e;
    value = value.replace(/(\d{2})(\d{2})/, "$1/$2");
    setNewCard({ ...newCard, expDate: value });
  };

  if (newCard)
    return (
      <div className="addresses bg-slate-50 block rounded-xl mb-2 p-6">
        <p className="pb-4 text-xl text-slate-800 font-bold">
          {card ? "Edit" : "Add"} Payment Method
        </p>
        <Input
          onChange={(e) => updateCard(e.target.value, "cardName")}
          value={newCard.cardName}
          label="Card Name"
          id="cardName"
        />
        <Input
          onChange={(e) => formatCreditCardNumber(e.target.value)}
          value={newCard.cardNumber}
          label="Card Number"
          id="cardNumber"
        />

        <div className="flex m-0 justify-between">
          <Input
            onChange={(e) => updateCard(e.target.value, "csv")}
            value={newCard.csv}
            label="CSV"
            id="csv"
            classes="w-1/2 mr-2"
          />
          <Input
            onChange={(e) => handleExpChange(e.target.value)}
            value={newCard.expDate}
            label="Exp. Date"
            classes="w-1/2 mr-2"
            id="expDate"
          />
        </div>

        <div onClick={() => null} className="mt-4">
          <Button title="Save Payment" />
        </div>
      </div>
    );
};

export default CCForm;
