import React, { useState } from "react";
import Button from "./Button";
import Input from "./Input";
import { UserContext } from "../context/User";

const AddressForm = ({ address }) => {
  const initAddress = {
    addressee: "",
    street_address: "",
    street_address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };

  //   const { updateAddress } = React.useContext(UserContext);
  const [newAddress, setNewAddress] = React.useState();

  React.useEffect(() => {
    if (setNewAddress(address)) {
      setNewAddress(address);
    } else {
      setNewAddress(initAddress);
    }

    console.log(newAddress);
  }, []);

  const updateAddress = (value, label) => {
    setNewAddress({ ...newAddress, [label]: value });
  };

  if (newAddress)
    return (
      <div className="addresses bg-slate-50 block rounded-xl mb-2 p-6">
        <p className="pb-4 text-xl text-slate-800 font-bold">
          {address ? "Edit" : "Add"} Address
        </p>
        <Input
          onChange={(e) => updateAddress(e.target.value, "addressee")}
          value={newAddress.addressee}
          label="Addressee"
          id="addressee"
        />
        <Input
          onChange={(e) => updateAddress(e.target.value, "street_address")}
          value={newAddress.street_address}
          label="Street Address"
          id="street_address"
        />
        <Input
          onChange={(e) => updateAddress(e.target.value, "street_address_2")}
          value={newAddress.street_address_2}
          label="Street Address 2"
          id="street_address_2"
        />

        <div className="flex m-0 justify-between">
          <Input
            onChange={(e) => updateAddress(e.target.value, "city")}
            value={newAddress.city}
            label="City"
            classes="w-full mr-2"
            id="city"
          />
          <Input
            onChange={(e) => updateAddress(e.target.value, "state")}
            value={newAddress.state}
            label="State"
            classes="mr-2"
            id="state"
          />
          <Input
            onChange={(e) => updateAddress(e.target.value, "zip")}
            value={newAddress.zip}
            label="Zip"
            id="zip"
          />
        </div>

        <Input
          onChange={(e) => updateAddress(e.target.value, "country")}
          value={newAddress.country}
          label="Country"
          id="country"
        />
        <div onClick={() => null} className="mt-4">
          <Button title="Save Address" />
        </div>
      </div>
    );
};

export default AddressForm;
