import React, { useState } from "react";

const Button = ({
  title,
  onClick,
  secondary,
  icon,
  className,

  awEvent,
  awValue,
}) => {
  const primaryStyles =
    "text-white bg-black hover:bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 focus:outline-none dark:focus:ring-primary flex items-center justify-center";
  const secondaryStyles =
    "py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700";
  return (
    <button
      {...(awEvent && { "data-aw-click-event": awEvent })}
      {...(awValue && { "data-aw-click-value": awValue })}
      onClick={onClick}
      type="button"
      className={`${secondary ? secondaryStyles : primaryStyles} ${className}`}
    >
      {icon && <i className={icon}></i>}
      {title}
    </button>
  );
};

export default Button;
