import React, { useState } from "react";

const Payment = ({ payment }) => {
  console.log(payment);
  const { cardNumber, expirationDate, cardType } = payment.payment.creditCard;
  return (
    <div className=" rounded-xl mt-4 box-shadow" style={{ maxWidth: 400 }}>
      <div className="bg-slate-500 text-white rounded-t-xl px-6 py-6">
        <i className={`text-4xl fa-brands fa-cc-${cardType.toLowerCase()}`}></i>
        <p className="text-2xl  p-0 m-0">Ending in {cardNumber}</p>
      </div>
      <div className="px-6 py-3">
        <p className="text-lg font-bold mb-0 pb-0">Tyler Hahn</p>
        <p className="pt-0 mt-0">Expires {expirationDate}</p>
      </div>
    </div>
  );
};

export default Payment;
