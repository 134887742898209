import React, { useState } from "react";
import { UserContext } from "../context/User";
import { convertTime, formatMoney } from "../utils";
import Accordion from "./Accordion";
import PlaceHolderImage from "../images/no_image.jpg";

const Orders = ({ limit }) => {
  const { user } = React.useContext(UserContext);
  const [orderLimit, setOrderLimit] = useState(limit);
  const [input, setInput] = useState("");
  const [sortCriteria, setSortCriteria] = useState("paid_at");
  const [sortOrder, setSortOrder] = useState("desc");

  React.useEffect(() => {
    if (!limit) {
      setOrderLimit(999999);
    }
  }, [limit]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const renderSearch = () => {
    if (limit) return;
    return (
      <div>
        <input
          className="bg-slate-100 w-full p-4 my-4 rounded"
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Search orders..."
        />
      </div>
    );
  };

  const renderOrders = () => {
    let filteredOrders = user.orders;

    if (input) {
      const searchTerm = input.toLowerCase();
      filteredOrders = filteredOrders.filter((order) => {
        return (
          order.so_number.toLowerCase().includes(searchTerm) ||
          order.fulfillment_status.toLowerCase().includes(searchTerm) ||
          order.financial_status.toLowerCase().includes(searchTerm) ||
          order.total.toString().includes(searchTerm)
        );
      });
    }

    filteredOrders.sort((a, b) => {
      const aValue = a[sortCriteria];
      const bValue = b[sortCriteria];

      if (sortOrder === "asc") {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
    });

    if (filteredOrders.length > 0) {
      return filteredOrders.slice(0, orderLimit).map((order, index) => {
        return (
          <Accordion
            key={order.id}
            title={
              <>
                <p>{order.so_number || order.id}</p>
                <p>{convertTime(order.paid_at)}</p>
                <p className="capitalize">{order.fulfillment_status}</p>
                <p className="capitalize hidden lg:block">
                  {order.financial_status}
                </p>
                <p className="hidden lg:block">{formatMoney(order.total)}</p>
              </>
            }
          >
            <div>
              {order.items.map((item) => {
                const {
                  company_name,
                  street_address,
                  street_address_2,
                  city,
                  state,
                  zip,
                  country,
                } = order.shipping_address;
                return (
                  <div
                    key={item.id}
                    className="flex justify-between py-4 border-b border-slate-300 last:border-none"
                  >
                    <div className="w-1/5">
                      <img
                        style={{ height: 50 }}
                        src={
                            (item.product && item.product.image_url)
                            ? item.product.image_url
                            : PlaceHolderImage
                        }
                        alt=""
                      />
                    </div>
                    <div className="w-1/5 mr-5">
                      <p className="m-0 font-bold"> {item.product_name}</p>
                      <p>{formatMoney(item.price * item.quantity)}</p>
                      <p>Qty {item.quantity}</p>
                    </div>
                    <div className="w-2/5">
                      <div className="addresses">
                        <p className="pb-1 font-bold">Ship To</p>
                        {company_name ? (
                          <p className=" pb-1">{company_name}</p>
                        ) : null}
                        {street_address ? (
                          <p className="pb-1">{street_address}</p>
                        ) : null}
                        {street_address_2 ? (
                          <p className="pb-1">{street_address_2}</p>
                        ) : null}
                        <div className="flex m-0">
                          {city ? <p className="pb-1">{city}</p> : null}
                          {state ? <p className="pb-1">{state}</p> : null},{" "}
                          {zip ? <p className="pb-1">{zip}</p> : null}
                        </div>

                        {country ? <p className="pb-1">{country}</p> : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Accordion>
        );
      });
    } else {
      return (
        <div>
          <p>You have no orders</p>
        </div>
      );
    }
  };

  const sortBy = (criteria) => {
    if (criteria === sortCriteria) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortCriteria(criteria);
      setSortOrder("asc");
    }
  };

  return (
    <div className="p-6 box-shadow rounded-lg border-t-8 border-blue-500">
      <p className="font-bold text-2xl m-0">Orders</p>
      {renderSearch()}
      <div className="grid grid-cols-3 lg:grid-cols-5 font-bold">
        <p onClick={() => sortBy("so_number")} className="cursor-pointer">
          Order #
          {sortCriteria === "so_number" && (
            <i
              className={`fa-regular fa-chevron-${
                sortOrder === "asc" ? "up" : "down"
              }`}
            ></i>
          )}
        </p>
        <p onClick={() => sortBy("paid_at")} className="cursor-pointer">
          Date
          {sortCriteria === "paid_at" && (
            <i
              className={`fa-regular fa-chevron-${
                sortOrder === "asc" ? "up" : "down"
              }`}
            ></i>
          )}
        </p>
        <p
          onClick={() => sortBy("fulfillment_status")}
          className="cursor-pointer"
        >
          Status
          {sortCriteria === "fulfillment_status" && (
            <i
              className={`fa-regular fa-chevron-${
                sortOrder === "asc" ? "up" : "down"
              }`}
            ></i>
          )}
        </p>
        <p
          onClick={() => sortBy("financial_status")}
          className="cursor-pointer hidden lg:block"
        >
          Payment
          {sortCriteria === "financial_status" && (
            <i
              className={`fa-regular fa-chevron-${
                sortOrder === "asc" ? "up" : "down"
              }`}
            ></i>
          )}
        </p>
        <p
          onClick={() => sortBy("total")}
          className="cursor-pointer hidden lg:block"
        >
          Total
          {sortCriteria === "total" && (
            <i
              className={`fa-regular fa-chevron-${
                sortOrder === "asc" ? "up" : "down"
              }`}
            ></i>
          )}
        </p>
      </div>
      <div>{renderOrders()}</div>
    </div>
  );
};

export default Orders;
