import React, { useState } from "react";

import Overview from "./Overview";
import Orders from "./Orders";
import Addresses from "./Addresses";
import Rewards from "./Rewards";
import PaymentBook from "./PaymentBook";

const View = ({ page, setPage }) => {
  const renderView = () => {
    switch (page) {
      case "overview":
        return <Overview setPage={setPage} />;
      case "orders":
        return <Orders />;
      case "addresses":
        return <Addresses />;
      case "rewards":
        return <Rewards />;
      case "payment":
        return <PaymentBook />;
      default:
        return <Overview />;
    }
  };
  return <div className="">{renderView()}</div>;
};

export default View;
