import React, { useState } from "react";
import { UserContext } from "../context/User";
import Modal from "./Modal";
import AddressForm from "./AddressForm";

const Addresses = ({ limit, setPage }) => {
  const { user } = React.useContext(UserContext);
  const [addressLimit, setAddressLimit] = useState(limit);

  React.useEffect(() => {
    if (!limit) {
      setAddressLimit(999999);
    }
  }, [limit]);

  const renderAddresses = () => {
    if (user.addresses.length > 0) {
      return user.addresses.map((address, index) => {
        const {
          addressee,
          street_address,
          street_address_2,
          city,
          state,
          zip,
          country,
        } = address;

        if (index <= addressLimit - 1) {
          return (
            <div
              key={address.id}
              className="addresses bg-slate-50 block rounded-xl mb-2 p-6"
            >
              {addressee ? <p className="font-bold pb-1">{addressee}</p> : null}
              {street_address ? <p className="pb-1">{street_address}</p> : null}
              {street_address_2 ? (
                <p className="pb-1">{street_address_2}</p>
              ) : null}
              <div className="flex m-0">
                {city ? <p className="pb-1">{city}</p> : null}
                {state ? <p className="pb-1">{state}</p> : null},{" "}
                {zip ? <p className="pb-1">{zip}</p> : null}
              </div>

              {country ? <p className="pb-1">{country}</p> : null}
              <div className="mt-4">
                <Modal title="Edit Address">
                  <AddressForm address={address} />
                </Modal>
              </div>
            </div>
          );
        } else {
          return null;
        }
      });
    } else
      return (
        <div>
          <p>You have no addresses</p>
          <Modal title="Add Address">
            <AddressForm isNew />
          </Modal>
        </div>
      );
  };

  return (
    <div className="p-8 box-shadow rounded-lg border-t-8 border-purple-500">
      <p className="font-bold text-xl m-0 pb-6">Addresses</p>
      {renderAddresses()}
      {limit && (
        <p
          onClick={() => setPage("addresses")}
          className="text-slate-600 underline cursor-pointer hoveR:text-slate-800"
        >
          Manage addresses ({user.addresses.length})
        </p>
      )}
    </div>
  );
};

export default Addresses;
