export const rewards = [
  {
    id: 1,
    name: "Free Shipping",
    description: "Free ground shipping on all orders",
    tiers: [0, 1, 2],
  },
  {
    id: 4,
    name: "Exclusive Swag",
    description: "Get free Blizzard Swag on your next order",
    tiers: [2],
  },
  {
    id: 5,
    name: "5% Cashback",
    description: "5% of your order total back in Blizzard Credit",
    tiers: [0, 1],
  },
  {
    id: 6,
    name: "10% Cashback",
    description: "10% of your order total back in Blizzard Credit",
    tiers: [2],
  },
  {
    id: 7,
    name: "Bonus Credit Events",
    description: "Exclusive access to bonus credit events",
    tiers: [2],
  },
];

export const legendBadge = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 88 64"
    style={{ enableBackground: "new 0 0 88 64" }}
  >
    <g>
      <path
        style={{ fill: "#FFFFFF" }}
        d="M80.7,24.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0.1v7h7
		l-4.8-6.4c-0.3-0.4-0.3-0.8,0-1.2L88,18H74.4L80.7,24.3C80.7,24.3,80.7,24.3,80.7,24.3z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M0,32h7v-7c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0
		l6.3-6.3H0l4.8,6.4c0.3,0.4,0.3,0.8,0,1.2L0,32z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M44,56c0.6,0,1-0.4,1-1V43.3l2.3-1.3h-6.6l2.3,1.3V55C43,55.6,43.4,56,44,56z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M44,10c-0.6,0-1,0.4-1,1v11.7L40.7,24h6.6L45,22.7V11C45,10.4,44.6,10,44,10z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M24.2,21.5c-0.3,0.5-0.1,1.1,0.4,1.4l1.9,1.1h4l-4.9-2.8C25,20.9,24.4,21.1,24.2,21.5z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M49.4,42.3c-0.3,0.2-0.5,0.5-0.5,0.9v11.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-9.3l8.1,4.7
		c0.5,0.3,1.1,0.1,1.4-0.4c0.3-0.5,0.1-1.1-0.4-1.4l-9.6-5.6C50.1,42.1,49.7,42.1,49.4,42.3z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M49.9,10.8c-0.6,0-1,0.4-1,1v11.1c0,0.4,0.2,0.7,0.5,0.9c0.2,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1
		l9.6-5.6c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-8.1,4.7v-9.3C50.9,11.2,50.4,10.8,49.9,10.8z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M38.1,55.2c0.6,0,1-0.4,1-1V43.2c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.7-0.2-1,0L28,47.9
		c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4l8.1-4.7v9.3C37.1,54.8,37.6,55.2,38.1,55.2z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M38.1,23.8c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.5-0.5,0.5-0.9V11.8c0-0.6-0.4-1-1-1s-1,0.4-1,1v9.3
		L29,16.4c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4l9.6,5.6C37.8,23.8,38,23.8,38.1,23.8z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M63.5,22.9c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4L57.6,24h4L63.5,22.9z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M24.5,43.1c-0.5,0.3-0.6,0.9-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l4.9-2.8h-4
		L24.5,43.1z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M63,45c0.3,0,0.7-0.2,0.9-0.5c0.3-0.5,0.1-1.1-0.4-1.4L61.6,42h-4l4.9,2.8C62.6,44.9,62.8,45,63,45z
		"
      />
      <polygon style={{ fill: "#FFFFFF" }} points="15,24 15,19.4 10.4,24 	" />
      <polygon style={{ fill: "#FFFFFF" }} points="73,19.4 73,24 77.6,24 	" />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M23.7,2.6l9.8,5.3c0.3,0.2,0.7,0.2,1,0L44,2.2l9.5,5.7c0.3,0.2,0.7,0.2,1,0l9.8-5.3L67.5,24h2
		L66.1,0.9c0-0.3-0.3-0.6-0.5-0.7c-0.3-0.1-0.6-0.1-0.9,0L54,5.8l-9.5-5.7c-0.3-0.2-0.7-0.2-1,0L34,5.8L23.4,0.1
		c-0.3-0.2-0.6-0.2-0.9,0c-0.3,0.1-0.5,0.4-0.5,0.7L18.4,24h2L23.7,2.6z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M70.2,42l0.7,4.5L44,61.8L17.1,46.5l0.7-4.5h-2L15,46.9c-0.1,0.4,0.1,0.8,0.5,1l28,16
		C43.7,64,43.8,64,44,64s0.3,0,0.5-0.1l28-16c0.4-0.2,0.6-0.6,0.5-1L72.3,42H70.2z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M69.7,34.7c0.2-0.4,0.3-1,0.3-1.8c0-0.7-0.1-1.3-0.3-1.6c-0.4-0.6-1-0.8-2.1-0.8h-2.9v5.1h2.9
		C68.7,35.5,69.4,35.3,69.7,34.7z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M9,40h70V26h-7H16H9V40z M63.1,29.1h4.6c1.7,0,2.7,0.5,3.3,1.4c0.4,0.6,0.5,1.3,0.5,2.4
		c0,1.2-0.1,1.9-0.5,2.5c-0.5,0.9-1.5,1.5-3.2,1.5h-4.7V29.1z M53,29.1h1.7l4.8,5.6v-5.6H61v7.8h-1.6l-4.9-5.7v5.7H53V29.1z
		 M43.9,29.1h7.4v1.4h-5.9v1.8H51v1.3h-5.7v1.9h5.9v1.4h-7.4V29.1z M33.9,30.5c0.7-0.8,1.9-1.5,3.9-1.5c2.1,0,3.3,0.7,3.9,1.7
		c0.2,0.4,0.3,0.8,0.4,1.1h-1.5c0-0.2-0.2-0.5-0.4-0.8c-0.4-0.4-1.1-0.7-2.3-0.7c-1.3,0-2.1,0.3-2.6,0.8c-0.4,0.4-0.5,1-0.5,1.9
		c0,0.9,0.1,1.4,0.5,1.8c0.5,0.6,1.4,0.8,2.6,0.8c1.2,0,1.9-0.2,2.5-0.9c0.3-0.3,0.3-0.8,0.3-1h-3.4v-1.3H42v4.4h-1.3l-0.1-0.9
		c-0.4,0.4-1.3,1.1-3.2,1.1c-1.5,0-2.8-0.4-3.6-1.4c-0.5-0.6-0.8-1.5-0.8-2.6C33.1,32,33.3,31.2,33.9,30.5z M24.6,29.1H32v1.4h-5.9
		v1.8h5.7v1.3h-5.7v1.9H32v1.4h-7.4V29.1z M16.5,29.1H18v6.4h5.2v1.4h-6.8V29.1z"
      />
    </g>
  </svg>
);

export const headlinerBadge = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 88 64"
    style={{ enableBackground: "new 0 0 88 64" }}
  >
    <g>
      <path
        style={{ fill: "#ffffff" }}
        d="M80.7,24.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0.1v7h7
		l-4.8-6.4c-0.3-0.4-0.3-0.8,0-1.2L88,18H74.4L80.7,24.3C80.7,24.3,80.7,24.3,80.7,24.3z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M0,32h7v-7c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0
		l6.3-6.3H0l4.8,6.4c0.3,0.4,0.3,0.8,0,1.2L0,32z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M44,56c0.6,0,1-0.4,1-1V43.3l2.3-1.3h-6.6l2.3,1.3V55C43,55.5,43.4,56,44,56z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M44,10c-0.6,0-1,0.4-1,1v11.7L40.7,24h6.6L45,22.7V11C45,10.4,44.6,10,44,10z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M24.2,21.5c-0.3,0.5-0.1,1.1,0.4,1.4l1.9,1.1h4l-4.9-2.8C25,20.9,24.4,21.1,24.2,21.5z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M49.4,42.3c-0.3,0.2-0.5,0.5-0.5,0.9v11.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-9.3l8.1,4.7
		c0.5,0.3,1.1,0.1,1.4-0.4c0.3-0.5,0.1-1.1-0.4-1.4l-9.6-5.6C50.1,42.1,49.7,42.1,49.4,42.3z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M49.9,10.8c-0.6,0-1,0.4-1,1v11.1c0,0.4,0.2,0.7,0.5,0.9c0.2,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1
		l9.6-5.6c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-8.1,4.7v-9.3C50.9,11.2,50.4,10.8,49.9,10.8z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M38.1,55.2c0.6,0,1-0.4,1-1V43.2c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.7-0.2-1,0L28,47.8
		c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4l8.1-4.7v9.3C37.1,54.8,37.6,55.2,38.1,55.2z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M38.1,23.8c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.5-0.5,0.5-0.9V11.8c0-0.6-0.4-1-1-1s-1,0.4-1,1v9.3
		L29,16.4c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4l9.6,5.6C37.8,23.8,38,23.8,38.1,23.8z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M63.5,22.9c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4L57.6,24h4L63.5,22.9z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M24.5,43.1c-0.5,0.3-0.6,0.9-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l4.9-2.8h-4
		L24.5,43.1z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M63,45c0.3,0,0.7-0.2,0.9-0.5c0.3-0.5,0.1-1.1-0.4-1.4L61.6,42h-4l4.9,2.8C62.6,44.9,62.8,45,63,45z
		"
      />
      <polygon style={{ fill: "#ffffff" }} points="15,24 15,19.4 10.4,24 	" />
      <polygon style={{ fill: "#ffffff" }} points="73,19.4 73,24 77.6,24 	" />
      <path
        style={{ fill: "#ffffff" }}
        d="M23.7,2.3l20,5.7C43.9,8,44.1,8,44.3,8l20-5.7L67.5,24h2L66.1,0.8c0-0.3-0.2-0.5-0.4-0.7
		C65.4,0,65.1,0,64.8,0L44,6L23.2,0c-0.3-0.1-0.6,0-0.8,0.1S22,0.6,21.9,0.8L18.4,24h2L23.7,2.3z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M70.2,42l0.7,4.5L44,61.8L17.1,46.5l0.7-4.5h-2L15,46.8c-0.1,0.4,0.1,0.8,0.5,1l28,16
		C43.7,64,43.8,64,44,64s0.3,0,0.5-0.1l28-16c0.4-0.2,0.6-0.6,0.5-1L72.3,42H70.2z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M9,40h70V26h-7H16H9V40z M68.5,29h4.2c1,0,1.6,0.3,1.9,0.9c0.2,0.4,0.3,0.8,0.3,1.4
		c0,0.6-0.1,1.1-0.3,1.4c-0.2,0.4-0.6,0.7-1,0.7l1.9,3.5h-1.6l-1.6-3.2H70V37h-1.4V29z M61.4,29h5.8v1.3h-4.4v2H67v1.3h-4.2v2h4.4
		V37h-5.9V29z M53.3,29H55l3.4,5.7V29h1.4v8h-1.6l-3.5-5.9V37h-1.4V29z M50.2,29h1.5v8h-1.5V29z M43.7,29h1.5v6.7h4.1V37h-5.5V29z
		 M35.7,29h3.7c1.2,0,2,0.3,2.4,0.9c0.5,0.6,0.7,1.4,0.7,3c0,1.7-0.2,2.5-0.6,3.2c-0.5,0.6-1.2,1-2.4,1h-3.8V29z M30.1,29H32l2.9,8
		h-1.5l-0.7-1.9h-3.4L28.6,37h-1.5L30.1,29z M20.7,29h5.8v1.3h-4.4v2h4.2v1.3h-4.2v2h4.4V37h-5.9V29z M12.5,29h1.5v3.3h3.7V29H19v8
		h-1.4v-3.3h-3.7V37h-1.5V29z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M73.4,32.2c0.1-0.2,0.1-0.5,0.1-0.7c0-0.3,0-0.6-0.1-0.7c-0.2-0.3-0.5-0.5-1-0.5H70c0,0,0,2.2,0,2.2
		h2.6C72.9,32.5,73.3,32.4,73.4,32.2z"
      />
      <polygon
        style={{ fill: "#ffffff" }}
        points="31.2,31 31,30.3 30.8,31 29.8,33.8 32.2,33.8 	"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M41,32.9c0-1-0.1-1.8-0.4-2.1c-0.4-0.4-0.7-0.5-1.4-0.5h-2v5.4h2c0.7,0,1.2-0.1,1.5-0.5
		C40.9,34.8,41,34,41,32.9z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M70,30.3h2.4c0.5,0,0.9,0.2,1,0.5c0.1,0.1,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7
		c-0.1,0.2-0.4,0.4-0.9,0.4H70C70,32.5,70,30.3,70,30.3z"
      />
    </g>
  </svg>
);

export const crewBadge = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 88 64"
    style={{ enableBackground: "new 0 0 88 64" }}
  >
    <g>
      <path
        style={{ fill: "#ffffff" }}
        d="M22.9,5h42.3l2.4,16h2L67,3.8C66.9,3.3,66.5,3,66,3H22c-0.5,0-0.9,0.4-1,0.9L18.4,21h2L22.9,5z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M70.2,39l0.7,4.5L44,58.8L17.1,43.4l0.7-4.5h-2L15,43.8c-0.1,0.4,0.1,0.8,0.5,1l28,16
		c0.2,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1l28-16c0.4-0.2,0.6-0.6,0.5-1L72.3,39H70.2z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M80.7,21.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0.1v7h7
		l-4.8-6.4c-0.3-0.4-0.3-0.8,0-1.2L88,15H74.4L80.7,21.3C80.7,21.3,80.7,21.3,80.7,21.3z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M0,29h7v-7c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0
		l6.3-6.3H0l4.8,6.4c0.3,0.4,0.3,0.8,0,1.2L0,29z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M44,53c0.6,0,1-0.4,1-1V40.3l2.3-1.3h-6.6l2.3,1.3V52C43,52.5,43.4,53,44,53z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M44,7c-0.6,0-1,0.4-1,1v11.7L40.7,21h6.6L45,19.6V8C45,7.4,44.6,7,44,7z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M24.2,18.5c-0.3,0.5-0.1,1.1,0.4,1.4l1.9,1.1h4l-4.9-2.8C25,17.9,24.4,18,24.2,18.5z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M49.4,39.3c-0.3,0.2-0.5,0.5-0.5,0.9v11.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-9.3l8.1,4.7
		c0.5,0.3,1.1,0.1,1.4-0.4s0.1-1.1-0.4-1.4l-9.6-5.6C50.1,39.1,49.7,39.1,49.4,39.3z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M49.9,7.7c-0.6,0-1,0.4-1,1v11.1c0,0.4,0.2,0.7,0.5,0.9c0.2,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1
		l9.6-5.6c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-8.1,4.7V8.7C50.9,8.2,50.4,7.7,49.9,7.7z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M38.1,52.2c0.6,0,1-0.4,1-1V40.1c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.7-0.2-1,0L28,44.8
		c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4l8.1-4.7v9.3C37.1,51.8,37.6,52.2,38.1,52.2z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M38.1,20.8c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.5-0.5,0.5-0.9V8.7c0-0.6-0.4-1-1-1s-1,0.4-1,1v9.3
		L29,13.4c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4l9.6,5.6C37.8,20.8,38,20.8,38.1,20.8z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M63.5,19.9c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4L57.6,21h4L63.5,19.9z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M24.5,40.1c-0.5,0.3-0.6,0.9-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l4.9-2.8h-4
		L24.5,40.1z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M63,41.9c0.3,0,0.7-0.2,0.9-0.5c0.3-0.5,0.1-1.1-0.4-1.4L61.6,39h-4l4.9,2.8
		C62.6,41.9,62.8,41.9,63,41.9z"
      />
      <polygon style={{ fill: "#ffffff" }} points="15,21 15,16.4 10.4,21 	" />
      <polygon style={{ fill: "#ffffff" }} points="73,16.4 73,21 77.6,21 	" />
      <path
        style={{ fill: "#ffffff" }}
        d="M39.1,29c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2,0-0.4-0.2-0.5c-0.2-0.3-0.6-0.4-1.4-0.4h-4.8v1.8h5
		C38.6,29.3,38.9,29.2,39.1,29z"
      />
      <path
        style={{ fill: "#ffffff" }}
        d="M9,37h70V23h-7H16H9V37z M55.4,26.1l3,6.4l2.5-6.4h2l2.5,6.4l3-6.4h1.6l-3.6,7.7h-2.1l-2.4-6l-2.4,6
		h-2.1l-3.7-7.7H55.4z M43.5,26.1h9v1.4h-7.4v1.7h7.3v1.4h-7.3v1.7h7.5v1.4h-9.1V26.1z M31.3,26.1h6.4c1.3,0,2.2,0.4,2.7,0.9
		c0.3,0.3,0.4,0.7,0.4,1.3c0,0.5-0.1,0.9-0.3,1.3c-0.3,0.4-0.8,0.8-1.6,0.9l2.8,3.3h-1.8l-2.7-3.1h-4.4v3.1h-1.6V26.1z M18.5,28.2
		c0.8-1.3,2.4-2.2,5.3-2.2c2.9,0,4.3,0.8,5.2,2.2c0.2,0.4,0.4,0.8,0.4,1.1h-1.7c0-0.2-0.2-0.4-0.4-0.6c-0.5-0.6-1.7-1.1-3.5-1.1
		c-1.9,0-3.3,0.6-3.8,1.6c-0.1,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.7,0.3,1c0.7,1,2.3,1.5,3.8,1.5c1.7,0,2.9-0.3,3.6-1.1
		c0.1-0.2,0.2-0.4,0.3-0.6h1.7c0,0.3-0.2,0.8-0.4,1.1c-0.7,1.3-2.3,2.1-5.1,2.1c-2.8,0-4.5-0.9-5.3-2c-0.3-0.6-0.5-1.2-0.5-1.9
		C18.1,29.3,18.2,28.7,18.5,28.2z"
      />
    </g>
  </svg>
);
